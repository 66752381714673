.App {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.congeInput {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.labelConge {
  width: 150px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 10px;
}

.inputConge {
  width: 150px;
  text-align: center;
}

header {
  margin-top: 2vh;
  display: flex;
  justify-content: center;
  height: 10vh;
  align-items: center;
  max-width: 88vh;
  min-width: 50.1%;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.inputMonthAndYear {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.select-css {
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: #888;
}
.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.select-css option {
  font-weight: normal;
}

.AppHeader {
  width: 100%;
  height: 10vh;
  margin-top: 4vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.monthAndYearContainer {
  text-align: center;
  margin: 0 5%;
  width: 200px;
}

.arrows {
  font-size: 35px;
}

h1 {
  width: 100%;
  text-align: center;
  font-size: 40px;
  margin: 0;
}

.inlineBlock {
  display: inline-block;
}

.monthContainer {
  margin-top: 5vh;
  height: 65vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.weeksContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 10vh;
  max-width: 88vh;
  min-width: 50.1%;
}

.dayCard {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  border: 1px solid;
  font-weight: bold;
  border-radius: 5px;
  font-size: 20px;
  width: 10vh;
}

.number {
  font-size: 25px;
}

.day {
  margin-top: 10px;
}

.today {
  color: green;
  font-weight: bold;
  border: 3px solid;
}

.dayContent {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.weekend {
  background-color: #529dcb;
  color: black;
}

.conge {
  background-color: lightgreen;
}

.ferie {
  background-color: #db5957;
}

.pointer {
  cursor: pointer;
}

.bg-grey {
  background-color: lightgrey;
}

@media screen and (max-width: 750px) {
  header {
    margin-top: 0.5vh;
    height: 14vh;
  }

  .AppHeader {
    height: 14vh;
  }

  .arrows {
    font-size: 4.8vh;
  }

  h1 {
    font-size: 5vh;
  }

  .App {
    margin-top: 5vh;
  }

  .monthContainer {
    height: 70vw;
    margin-top: 5vh;
  }

  .weeksContainer {
    width: 90vw;
    height: 10vw;
  }

  .dayCard {
    height: 10vw;
    width: 10vw;
    font-size: 2vw;
  }

  .number {
    font-size: 3vw;
  }
}
